.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grid__item-img {
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  z-index: 1;
}

.App-back {
 height: 90vh;  
 background-image:  url('images/background-degradado.jpg');
 background-size: cover;
 background-position: center center;
}

.Img-margin {
  padding-left: 40px ;
  padding-top:  40px ;
}

.App-logohh {
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttonalign{
  align-items: center;
}

.posfix{
  position: fixed;
}





.language {
  align-items:right ;
}


