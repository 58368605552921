.Howit {

    background-color: #1A1423;
    background-image: url('images/fondo-hh.png');
    background-size: cover;
    background-position: center center;  
    background-repeat: no-repeat;
  
  
  }

  .works {

    font-size: 50px ;

    background: linear-gradient( to right, #19A5F4 , #7507fA);
    
    font-weight: bold;

    text-align: center;

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .howit-lottie {
    width: auto;
  }