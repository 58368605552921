.testimonials{

    height: 800px ;  
    background: #1A1423;
    padding: 200px 0;

    /*background: linear-gradient( #1a1423 , #7507F4);*/
  
  }

  .bloque {
    width: 352px;
    padding: 50px;
    margin: 0 25px;
    border-style: solid;
    border-width: 2px;
    border-color: white;

  }

