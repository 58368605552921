.book {

    height: 100vh;
    background-color: grey;

}

.book-in {

    height: 100vh;
    color: white; 
    background-image: url(/src/images/background-menu.jpg) ;
    background-size: cover;
    background-position: center center;

}


.vl {
    border-left: 2px solid white;
    padding-left: 50px;
    /*height: 500px;*/
  }