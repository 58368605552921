.second { 
    padding: 200px 0;
    background:  linear-gradient(180deg, rgba(26,20,35,1) 0%, rgba(117,7,250,1) 20%, rgba(117,7,250,1) 80%, rgba(26,20,35,1) 100%);  
  }
.ficha-1 {
position: absolute;
right: 60px;
}
.ficha-2 {
    position: absolute;
    right: -261px;
}
