.climb {
    padding: 200px 0;
    background-color: #1A1423 ;
}


.buttonInfo-1 {
    background: linear-gradient(to right, #19A5F4 , #7507F4);
    font-size: 34px; 
    border: none;
    border-radius: 0;
    word-wrap: break-word;
    height: 70px;
    width: 100%;
    
  }

  .buttonInfo-2 {

    background: linear-gradient(180deg, rgba(87,86,88,1) 0%, rgba(26,20,35,1) 100%);
    font-size: 24px; 
    line-height: 24px;
    border: none;
    border-radius: 0;
    word-wrap: break-word;
    height: 70px;
    width: 100%;
  }

  .div-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120px;
    background: linear-gradient(to right, #19A5F4 , #1A1423);
    border: none;
    border-radius: 10px;
    word-wrap: break-word;

  }

  .div-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120px ;
    background: linear-gradient(to right, #7507F4 , #1A1423);
    border: none;
    border-radius: 10px;
    word-wrap: break-word;
  }

  .div-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px;
    border: none;
    text-decoration:line-through;
    color: white;
    word-wrap: break-word;
    opacity: .3;

  }
  .div-4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70px ;
    background: linear-gradient(to right, #7507F4 , #1A1423);
    border: none;
    border-radius: 10px;
    word-wrap: break-word;
  }
