.partners{

    background:  linear-gradient(180deg, rgba(26,20,35,1) 0%, rgba(117,7,250,1) 20%, rgba(117,7,250,1) 80%, rgba(26,20,35,1) 100%); 
    padding: 200px 0;

  }

  .logo-part {
    height: 233px;
    width: 233px;
    background: linear-gradient(180deg, rgba(40,167,241,1) 0%, rgba(117,7,250,1) 100%);
  }
