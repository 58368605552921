

.about {

    height: 100vh;
    background-image: url(/src/images/background-menu.jpg);
    background-position: center center;
    background-size: cover ;

}


.frame2 {

    border-left: 1px solid white;
    height: auto;
    
}


