.new {

    height: 100vh;

    background: linear-gradient(45deg, #7507FA 10% , #1A1423 90% , #19A5F4  100%);



}

.title {
    color: white ;
    font-size: 35px;
}

.frame3 {

    color: red;

    font-size: 15px;


}
