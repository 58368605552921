.optim {
  
    background-color: #1A1423;
    background-image: url('images/fondo-optimizing.png');
    background-size: contain;
    background-position: center right;  
    background-repeat: no-repeat;  
    padding: 200px 0;
  
  }

  .opsearch {

    font-size: 60px ;
    background: linear-gradient(to right, #19A5F4 , #7507F4);    
    background-clip: text;
    -webkit-text-fill-color: transparent;



  }

  .optext {
    display: inline ;
    font-size: 30px ;
    color: white;
  }

  .inline {

    font-size: 30px ;
    color: white;


    display: inline
  }