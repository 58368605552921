@font-face {
  font-family: "Satoshi-Bold";
  src: local("Satoshi-Bold"),
    url("./fonts/Satoshi-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-BoldItalic";
  src: local("Satoshi-BoldItalic"),
    url("./fonts/Satoshi-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Italic";
  src: local("Satoshi-Italic"),
    url("./fonts/Satoshi-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Medium";
  src: local("Satoshi-Medium"),
    url("./fonts/Satoshi-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-MediumItalic";
  src: local("Satoshi-MediumItalic"),
    url("./fonts/Satoshi-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Satoshi-Regular";
  src: local("Satoshi-Regular"),
    url("./fonts/Satoshi-Regular.ttf") format("truetype");
}
/*GENERALES TEXTO*/

body {
  font-family: "Satoshi-Regular";
}
a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #19A5F4;
}

.h1 {
  font-size: 60px;
  line-height: 70px;  
}
.h2 {
  font-size: 45px;
  line-height: 55px;
}
.h3 {
  font-size: 34px;
  line-height: 42px;
}
.body-0 {
  font-size: 34px;
  line-height: 45px;
}
.body-1 {
  font-size: 24px;
  line-height: 32px;
}
.body-2 {
  font-size: 20px;
  line-height: 26px;
}
.body-3 {
  font-size: 16px;
  line-height: 22px;
}
.body-4 {
  font-size: 12px;
  line-height: 14px;
}
.font-regular {
  font-family: "Satoshi-Regular";
}
.font-italic {
  font-family: "Satoshi-Italic";
}
.font-medium {
  font-family: "Satoshi-Medium";
}
.font-medium-italic {
  font-family: "Satoshi-MediumItalic";
}
.font-bold {
  font-family: "Satoshi-Bold";
}
.font-bold-italic {
  font-family: "Satoshi-BoldItalic";
}

/*GENERALES COLOR*/
.text-gradient-bp {
  background: linear-gradient(to right, #19A5F4 , #7507F4);    
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient-bg {
  background: linear-gradient(to right, #19A5F4 , #61E294);   
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* GENERALES BOTONES*/
.button-1 {
    padding: 20px 30px;
    background: linear-gradient(to right, #19A5F4 , #7507F4);
    font-size: 20px; 
    box-shadow: inset -2px 2px 7px #FFFFFF33, 0px 7px 8px #000000;
    border-radius: 0;
    border: none;
    font-family: "Satoshi-Bold";
}

.button-1:hover {
  background: linear-gradient(to right, #7507F4, #19A5F4);
}
/* Generales Forms*/
label {
  display: none;
}
input[type="text"], input[type="tel"], input[type="email"]{
  background-color: rgba(255, 255, 255, 0);
  border-radius: 0;
  color: white;
  padding: 15px 15px;

}

